<template>
    <div>
        <ContactBrossure />
    </div>

</template>

<script>
import ContactBrossure from '@/components/FormBrossure.vue'

export default {
    components:{
        ContactBrossure
    }
}
</script>