var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"file-uploader-form file-uploader-form--brossure d-lg-flex"},[_c('div',{staticClass:"d-lg-flex w-100"},[_c('div',{staticClass:"leftside col-12 col-lg-6 text-left text-white pb-5"},[_c('h3',{staticClass:"pt-5 pt-md-0"},[_vm._v(_vm._s(_vm.$store.state.translation.el_upload_form_title))]),_c('p',[_vm._v(_vm._s(_vm.$store.state.translation.el_upload_form_text))])]),_c('div',{staticClass:"col-12 col-lg-6 d-flex flex-column align-items-center screen-height p-0 pb-md-0"},[_c('div',{staticClass:"row position-relative w-100 p-0"},[_c('div',{staticClass:"form-container w-100 download-form"},[(!_vm.formSubmitted || _vm.sendingError)?_c('h3',{staticClass:"text-white p-3 mb-5 text-uppercase"},[_vm._v(_vm._s(_vm.$store.state.translation.dm_form_upload_title))]):_vm._e(),(!_vm.formSubmitted || _vm.sendingError)?_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"rules":{'required':true,'min': 2},"name":("" + (_vm.$store.state.translation.el_form_firstname))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.firstName),expression:"form.firstName"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"input-live-help input-live-1-feedback","placeholder":("" + (_vm.$store.state.translation.form_firstname)),"id":"surname"},domProps:{"value":(_vm.form.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "firstName", $event.target.value)}}})]),_c('portal',{attrs:{"to":"firstName"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("surname", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"rules":"required|min:2","name":("" + (_vm.$store.state.translation.el_form_lastname))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.lastName),expression:"form.lastName"}],staticClass:"form-control",attrs:{"type":"text","aria-describedby":"input-live-help input-live-1-feedback","placeholder":("" + (_vm.$store.state.translation.form_lastname)),"id":"lastname"},domProps:{"value":(_vm.form.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "lastName", $event.target.value)}}})]),_c('portal',{attrs:{"to":"lastName"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("forname", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('validation-provider',{attrs:{"rules":"required|email","name":("" + (_vm.$store.state.translation.el_form_email))},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var failedRules = ref.failedRules;
var classes = ref.classes;
return [_c('div',{staticClass:"isValidate",class:classes},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"}],staticClass:"form-control",attrs:{"type":"text","placeholder":("" + (_vm.$store.state.translation.form_email)),"name":"E-mail cím","id":"email"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.form, "email", $event.target.value)}}})]),_c('portal',{attrs:{"to":"email"}},[_c('div',{staticClass:"error"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(Object.entries(failedRules)[0]),expression:"Object.entries(failedRules)[0]"}]},[_vm._v(_vm._s(_vm.getErrorMessage("email", Object.entries(failedRules)[0])))])])])]}}],null,true)})],1)]),(_vm.sendingError)?_c('div',{staticClass:"error error--file justify-content-center"},[_c('span',[_vm._v(_vm._s(_vm.getErrorMessage("errorApi", Object.entries(_vm.apiErrorText)[0])))])]):_vm._e(),_c('div',{staticClass:"d-flex justify-content-between"},[_c('b-button',{attrs:{"type":"submit","variant":"btn btn--submit download-btn"}},[_vm._v(_vm._s(_vm.$store.state.translation.el_form_download_btn)+" "),_c('img',{staticClass:"img-fluid mb-1",attrs:{"src":require("../assets/icons/arrow-download.svg")}})])],1)])]}}],null,false,3228346642)}):_vm._e(),(_vm.formSubmitted && !_vm.sendingError)?_c('div',{staticClass:"d-flex justify-content-center align-items-center text-white download-success"},[_c('h1',{staticClass:"text-center text-uppercase"},[_vm._v(_vm._s(_vm.$store.state.translation.el_formBrossure_success_title))])]):_vm._e()],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }