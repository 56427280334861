<template>
  <div class="file-uploader-form file-uploader-form--brossure d-lg-flex">
    <div class="d-lg-flex w-100">
      <div class="leftside col-12 col-lg-6 text-left text-white pb-5">
          <h3 class="pt-5 pt-md-0">{{ $store.state.translation.el_upload_form_title }}</h3>
          <p>{{ $store.state.translation.el_upload_form_text }}</p>
      </div>
      <div class="col-12 col-lg-6 d-flex flex-column align-items-center screen-height p-0 pb-md-0">
        <div class="row position-relative w-100 p-0">
          <div class="form-container w-100 download-form">
            <h3 class="text-white p-3 mb-5 text-uppercase" v-if="!formSubmitted || sendingError">{{ $store.state.translation.dm_form_upload_title }}</h3>
            <!-- FORM - user data -->
            <validation-observer ref="observer" v-slot="{ handleSubmit }" v-if="!formSubmitted || sendingError">
              <form @submit.prevent="handleSubmit(onSubmit)">
                <div class="row">
                  <div class="col-12">
                    <validation-provider
                        :rules="{'required':true,'min': 2}"
                        :name="`${$store.state.translation.el_form_firstname}`"
                        v-slot="{ failedRules, classes}"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.firstName"
                            class="form-control"
                            type="text"
                            aria-describedby="input-live-help input-live-1-feedback"
                            :placeholder="`${$store.state.translation.form_firstname}`"
                            id="surname"
                        />
                      </div>
                      <portal to="firstName">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("surname", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <validation-provider
                        rules="required|min:2"
                        :name="`${$store.state.translation.el_form_lastname}`"
                        v-slot="{ failedRules, classes }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.lastName"
                            type="text"
                            class="form-control"
                            aria-describedby="input-live-help input-live-1-feedback"
                            :placeholder="`${$store.state.translation.form_lastname}`"
                            id="lastname"
                        />
                      </div>
                      <portal to="lastName">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("forname", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <validation-provider
                        rules="required|email"
                        :name="`${$store.state.translation.el_form_email}`"
                        v-slot="{  failedRules, classes  }"
                    >
                      <div class="isValidate" :class="classes">
                        <input
                            v-model="form.email"
                            type="text"
                            :placeholder="`${$store.state.translation.form_email}`"
                            class="form-control"
                            name="E-mail cím"
                            id="email"
                        />
                      </div>
                      <portal to="email">
                        <div class="error">
                            <span
                                v-show="Object.entries(failedRules)[0]">{{
                                getErrorMessage("email", Object.entries(failedRules)[0])
                              }}</span>
                        </div>
                      </portal>
                    </validation-provider>
                  </div>
                </div>
                <div v-if="sendingError" class="error error--file justify-content-center">
                  <span >{{ getErrorMessage("errorApi", Object.entries(apiErrorText)[0]) }}</span>
                </div>
                <div class="d-flex justify-content-between">
                  <b-button
                      type="submit"
                      variant="btn btn--submit download-btn"
                  >{{ $store.state.translation.el_form_download_btn }}
                    <img src="../assets/icons/arrow-download.svg" class="img-fluid mb-1">
                  </b-button>
                </div>
                

              </form>
            </validation-observer>

            <div class="d-flex justify-content-center align-items-center text-white download-success" v-if="formSubmitted && !sendingError">
              <h1 class="text-center text-uppercase">{{ $store.state.translation.el_formBrossure_success_title }}</h1>
            </div>
          </div>
        </div>
      </div>
        <!--<div v-if="formSubmitted && !sendingError"
            class=" w-100 row uploaded-form-screen justify-content-center align-content-center">
            
            <div class="col-md-6">
              
              <h2 class="text-center " >{{ $store.state.translation.el_formBrossure_subtitle }}</h2>
              <div class="d-flex justify-content-center ">          
                <router-link :to="{ name: 'Home', params:{lang: selectedLangCode}}"
                            @click="backToForm()"
                            class="btn btn--white"
                >{{ $store.state.translation.el_formBrossure_uploaded_screen_home }}
                </router-link>
              </div>
          </div>
        </div>-->
      </div>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
  extend
} from 'vee-validate/dist/vee-validate.full';
import {setInteractionMode} from "vee-validate";
import errorMessages from "@/assets/errorMessages.json";
import {required, email} from "vee-validate/dist/rules";

setInteractionMode("eager");
// Add a validation rules
extend("min", {
  validate(value, args) {
    return value.length >= args.length;
  },
  params: ["length"],
  message: 'At least 3 characters are required.'
});
extend("email", {
  ...email,
  message: 'Field Email Address must have a valid form.'
});
extend("required", {
  ...required,
  message: '{_field_} is required.'
});


export default {
  name: "BrossureDownloadForm",
  data() {
    return {            
      show: false,
      formSubmitted: false,
      form: {
        firstName: "",
        lastName: "",
        email: ""        
      },      
      baseUrl: process.env.VUE_APP_BASE_URL,
      sendingError: false,
      apiErrorText: {
        required: ""
      },
    };
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    getErrorMessage(fieldName, violation) {
      return violation ? (errorMessages[this.$store.state.language_id][fieldName] && errorMessages[this.$store.state.language_id][fieldName][violation[0]]) ?? violation[1] : undefined;
    },   
    onSubmit() {
      const _this = this;
      const formData = {        
        first_name: _this.form.firstName,
        last_name: _this.form.lastName,
        email: _this.form.email     
      }
    
      _this.post("earthlings_download",formData,{})
        .then(() => {
          //send an email to the earthlings team
              _this.post(
                  "/emailsender/6/1",
                  {
                    to: process.env.VUE_APP_EMAIL,
                    cc: process.env.VUE_APP_EMAIL_CC,
                    bcc: process.env.VUE_APP_EMAIL_BCC,
                    data_replace: {
                      first_name: _this.form.firstName,
                      last_name: _this.form.lastName,
                      email: _this.form.email                        
                    },
                  },
                  {}).then(() => {
                    _this.sendingError = false;
              }).catch(resp => {
                console.log('email sender error', resp)                
                _this.sendingError = true;

              })
            },
        ).then(() => {
            //send an email to the user
              _this.post(
                  `/emailsender/5/${this.$store.state.language_id}`,
                  {
                    to: _this.form.email,
                    data_replace: {
                      first_name: _this.form.firstName,
                      last_name: _this.form.lastName
                    },
                  },
                  {}).then(() => {
                    _this.formSubmitted = true;
                    _this.sendingError = false;
                    _this.resetForm()
              }).catch(resp => {
                console.log('email sender error - 2', resp)
                _this.sendingError = true;
              })                   
          }).catch((resp) => {
            console.log('error 2', resp)       
            _this.sendingError = true;
          })
      
    },
    resetForm() {
      this.form.lastName = "";
      this.form.firstName = "";
      this.form.email = "";      
      this.apiErrorText = {
        required: ""
      }      
    },
    backToForm() {
      this.formSubmitted = false;
    }     
  }
}
</script>
